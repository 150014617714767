import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Products from "../components/product-list/products";
import CategoriesChildrenFilter from "../components/CategoryChildrenFilter";
import PaginationTwo from "../components/PaginationTwo";

export default function KidsPage({ data, pageContext }) {
  return (
    <Layout
      title="Детская одежда"
      description="Трикотажная фабрика IMKON. Элегантность, удобство, качество"
    >
      <div className="bg-white">
        <div className="max-w-2xl mx-auto py-5 px-4 sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8">
          <CategoriesChildrenFilter activeCategory={pageContext.category} />
          <Products data={data.kids} />
          <PaginationTwo
            pageSize={8}
            totalCount={data.kids.totalCount}
            currentPage={pageContext.currentPage || 1}
            skip={pageContext.skip}
            base="/kids"
          />
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($skip: Int = 0, $pageSize: Int = 8) {
    kids: allSanityChildren(limit: $pageSize, skip: $skip) {
      totalCount
      nodes {
        name
        category {
          name
          id
        }
        slug {
          current
        }
        id
        image {
          asset {
            gatsbyImageData(width: 500)
          }
        }
      }
    }
  }
`;
